 <template>
  <el-dialog
    title="合同上传"
    top="1vh"
    width="95%"
    :visible.sync="is_show_in_page"
    :modal="false"
    @close="close"
  >
    <div class="contract-container" ref="content">
      <div class="head">
        <div
          class="top"
          style="font-size: 35px; font-weight: bold; color: black"
        >
          安徽省骏功物联网科技有限公司
        </div>
        <div
          class="top1"
          style="font-size: 35px; font-weight: bold; color: black"
        >
          委托运输用户协议
        </div>
      </div>
      <div class="head-right">
        <div class="contract_number">
          合同编号：
          <input
            type="text"
            v-model="contract_num"
            class="input"
            style="width: 400px"
          />
        </div>
        <div class="contract_addr">合同签订地：安庆市迎江区</div>
      </div>
      <div class="content">
        <div class="form_info">
          <div class="item">甲方（托运方）：{{ data1.name }}</div>
          <div class="item">法定代表人：{{ data1.legal_person }}</div>
          <div class="item">注册地址：{{ data1.addr }}</div>
          <div class="item">统一社会信用代码：{{ data1.license_code }}</div>
          <div class="item">联系人:{{ data1.app_user_info.name }}</div>
          <div class="item">联系地址：{{ data1.app_user_info.addr }}</div>
          <div class="item">联系电话：{{ data1.app_user_info.tel }}</div>
          <div class="item">微信：</div>
          <div class="item">邮箱：</div>
        </div>
        <div class="form_info" style="margin-top: 30px">
          <div class="item">乙方（承运方）：安徽省骏功物联网科技有限公司</div>
          <div class="item">法定代表人：黄宇翔</div>
          <div class="item">
            注册地址：安徽省安庆市迎江区绿地迎江世纪城紫峰大厦B座2401、2420、2421室
          </div>
          <div class="item">统一社会信用代码：91340802MA2WF25Y7R</div>
          <div class="item">联系人:</div>
          <div class="item">
            联系地址：安徽省安庆市迎江区绿地迎江世纪城紫峰大厦B座2401、2420、2421室
          </div>
          <div class="item">联系电话：</div>
          <div class="item">邮箱：</div>
        </div>
        <div class="content_tips">
          <div class="item">
            鉴于：
          </div>
          <div class="item">
            （1）甲方及其关联方对长期、稳定的优质运力有迫切的需求，希望享受安全、高效的货物运输服务。
乙方及其关联方是国内网络货运经营者，能通过先进的骏功物流网络货运平台（以下简称“骏功物流”
或“平台”）进行物流资源集约整合，高效利用社会货运车辆，为客户组织运力、调配车辆，为用户提
供货物供运输服务。
          </div>
          <div class="item">
           （2）现甲乙双方经友好协商，在自愿平等、公平诚信的基础上，遵循优势互补、互利共赢的原则，
根据相关法律法规规定，就承运方为托运人提供网络货物运输服务达成一致意见，以资双方共同遵守。
          </div>
          <div class="title">第一条 总则</div>
          <div class="item">
            甲乙双方同意展开合作，通过乙方打造的骏功物流网络货运平台，调配车辆、组织运力，逐步使得甲方获得安全、高效的运力解决方案。
          </div>
          <div class="item">
            双方本着优势互补、互利共赢的原则，经友好协商一致，达成本协议如下：
          </div>
          <div class="title">一、 合作的基本方式</div>
          <div class="item">
            本协议框架下，乙方（下称承运方）为甲方（下称托运人）在中国大陆范围内提供本协议约定的网
络货物运输服务，本协议中的一切权利和义务适用于托运人方和承运方的关联方；每一项网络货运业务
权利义务的享有方与承担方以骏功物流平台运单显示的法律主体为准，双方了解并同意其关联方违反本
协议所产生的责任后果由本协议签约方承担。
          </div>
          <div class="title">第二条 平台注册</div>
          <div class="item">
           2.1 骏功物流网络货运平台是承运方及其关联方运营的网络货物运输服务平台，托运人与承运方签
署本协议后，承运方将协助和指导托运人在骏功物流网络货运平台开通专属于托运人方的账户，托运人
方应出具其法定代表人或其他代理人（非承运方工作人员）操作托运人方账户的授权委托书。在双方合
作期间，托运人方应保管好账户名及密码，如托运人方的账户名或密码遗失或泄露的，承运方将根据托
运人方书面申请及时免费为托运人方进行账户重置或找回密码。
          </div>
          <div class="item">2.2 托运人方注册账户成功后，可使用该账户在骏功物流网络货运平台进行订单发布、运单管理、
发票开具申请等操作。</div>
          <div class="item">2.3 托运人管理账号在平台进行的一切操作均视为托运人的操作，托运人应当就此承担全部责任与
法律后果，严禁托运人方要求承运方业务人员为其代操作系统。</div>
          <div class="title">第三条 合作模式</div>
          <div class="item">3.1 托运人在平台发布订单信息后，承运方作为网络货运人，按骏功物流网络货运平台的规则与托
运人达成货物运输交易并签署运输协议。根据托运人的运输需求，承运方作为网络货运人，通过骏功物
流网络货运平台推荐或指定合适的实际承运人来完成货物运输任务，由承运方与实际承运人通过骏功物
流网络货运平台达成货物运输协议。在实际承运人完成货物运输任务后，由托运人通过骏功物流网络货
运平台指定的线上支付方式向实际承运人支付该趟运输业务的运费以及平台的服务费。</div>
          <div class="item">3.2 承运方应要求实际承运人在托运人要求的配送时间内将货物安全送达运单指定地点。</div>
          <div class="item">3.3 托运人或托运人指定的收货方接收货物后，应通过平台及时确认收货。如托运人未在指定时间
内确认收货或是确认回单，系统自动确认的，视为托运人已确认，托运人在平台的确认（包括系统自动
确认）行为或对回单的签收，作为双方结算本次货物运输费用的依据。</div>
          <div class="title">第四条 托运人的权利和义务</div>
          <div class="item">4.1 托运人的权利</div>
          <div class="item">4.1.1 托运人有权要求承运方按照平台发布的运单中约定的时限及方式将货物送达托运人指定地点。</div>
          <div class="item">4.1.2 托运人因自身原因需要变更运单信息的（包括但不限于变更收货地点、收货时间、收货人、
取消运单等），但需在货物出仓前或是货物未运达前，前述运单信息的变更需要由托运人在平台操作或
书面通知，因托运人变更运输指令而产生的费用由托运人承担。</div>
          <div class="item">4.2 托运人的义务</div>
          <div class="item">4.2.1 托运人保证其在骏功物流平台所发布的货运信息真实、准确、完整，严格遵守相关法律法规
合法经营，不存在虚假、遗漏或误导的情况。</div>
          <div class="item">4.2.2 托运人不得托运法律法规以及政府有关部门政策所规定的禁止运输的易燃易爆、危险品 和
禁运品，托运人应配合实际承运人对托运货物进行核查，如实际承运人发现货物属于违禁品或是危险货
物的，实际承运人有权拒绝装货。</div>
          <div class="item">4.2.3 托运人的货物运输需要办理审批、检验等手续的，托运人应当将办理完有关手续的文件提交
实际承运人并及时通知承运方。托运人应当按照行业标准及国家及行业运输安全标准或货物运输安全的
原则包装货物。因货物包装问题导致的货物损失，由托运人自行承担。</div>
          <div class="item">4.2.4 托运人有义务根据本协议相关规定，向承运方及时支付费用，托运人应保证支付款项的法律
主体、进行货物运输交易的法律主体以及接受发票的法律主体保持一致。</div>
          <div class="item">4.2.5 托运人理解网络货运业务模式的特殊性，承运方委托实际承运人并通过平台向托运人指定特
定司机和车辆的，托运人必须严格要求线下现场人员在将货物交运前线下核查司机和车辆与线上信息的
一致性，并在线下核查车辆行驶证、道路运输、司机驾驶证、从业资格 证等有效证件，以及车辆当年度
机动车交强险、年检标志。如存在司机/车辆不一致或是缺少任一有效证件及保险的交强险、年检标志情
况，托运人有权拒绝将货物交运，并应当及时通知承运方。若因出现上述情况但是托运人未及时通知承
运方而将货物交运，导致的损失（包括但不限于发生货损无法获得保险赔偿）由托运人承担责任。</div>
          <div class="item">4.2.6 托运人保证其在本协议有效期内，其不从事与承运方相同或是相近似的业务，对托运人发布
且经承运方确认的运单，托运人不得同时在其他网络货运平台发布，否则承运方有权单方无责解除本协
议，且承运方因此产生的全部损失由托运人承担。</div>
          <div class="item">4.2.7 托运人应当自觉遵守承运方骏功物流平台发布的各类规则，包括《个人信息管理规则》《交
易规则和保障条款》《用户授权协议》，维护骏功物流网络货运平台的交易秩序。</div>
          <div class="title">第五条 承运方的权利和义务</div>
          <div class="item">5.1 承运方的权利</div>
          <div class="item">5.1.1 承运方有权依据本协议约定，收取相应的运输费用。托运人逾期支付运费的，托运人应以未
付费用为准，以每日万分之五的标准向承运方支付违约金；托运人逾期支付超过五日的，承运方有权直
接解除本协议，并有权要求托运人赔偿其所有直接损失。</div>
          <div class="item">5.1.2 承运方有权基于自身业务发展需要、主管机关要求或是政策变化的要求，变更、调整或取消
本协议项下的相关服务，届时以承运方实际提供的服务为准。</div>
          <div class="item">5.2 承运方的义务</div>
          <div class="item">5.2.1 承运方有义务要求实际承运人按照相关法律法规的要求，利用恰当的存储方式、运输工具，
安全、合法地配送货物。未经托运人同意，承运方不得擅自改变托运人交运货物的外观形态及双方已经
确定的运输方式。</div>
          <div class="item">5.2.2 承运方有义务按照运单要求的车型安排车辆，并确保车辆在运输过程中正常运行，对运输过
程中出现的任何异常第一时间通知托运人，并采取适当措施减少或消除托运人的损失。</div>
          <div class="item">5.2.3 承运方有义务要求实际承运人将货物安全、如数、无损、及时地运达托运人指定的地点，交
于收货人并办理交接手续。</div>
          <div class="item">5.2.4 承运方将持续对骏功物流网络货运平台进行技术优化及改进，维护平台的稳定运行，建立完
善的客户响应机制，以确保托运人获得优质、高效的服务。在骏功物流网络货运平台出现故障时，承运
方应尽快解决问题、排除相关故障。</div>
          <div class="item">5.2.5 托运人与实际承运人之间产生本协议约定的特定争议，承运方协调双方解决。</div>
          <div class="title">第六条 风险转移</div>
          <div class="item">6.1 货交承运方后风险转移，即托运人将货物交付给实际承运人后货物毁损或灭失的风险转移。由
于网络货运平台经营模式的特殊性，货物并不经手承运方，而是直接交到实际承运人手上，因此货物发
生损坏或灭失后，先由承运方要求实际承运人承担赔偿责任，再由承运方向托运人承担赔偿责任。但货
物的毁损、灭失、短少、变质、污染等是因不可抗力、货物本身的自然属性或者合理损耗以及托运人、
收货人的过错等非承运方及承运方委托的实际承运人原因造成的（包括但不限于易碎品、贵重物品、高
附加值品等未提前书面告知且未选择投保），承运方不承担赔偿责任。</div>
          <div class="item">6.2 托运人应在将货物交付实际承运人运输之前自行购买货运综合险、货物责任险及其他相关保险，
确保在发生货损时可获得保险公司的赔付，货物运输保险受益人为托运人，保险费用由托运人承担。若
托运人未及时购买或购买的货运险保险金额不足以覆盖货损及其他损失和责任的，托运人应提前书面告
知承运方，由承运方提供追加投保的服务，追加保险的金额由托运人承担。双方确认，在本协议合作范
围内承运方对双方签订的每笔运单项下货物的毁损、灭失、短少、变质、污染等，承运方承担的最高赔
偿限额为该次运输所收取的运费两倍，承运方对超出该限额的损失不承担任何赔偿责任。</div>
          <div class="item">6.3 托运人确认并知晓，本协议下的货物运输服务由实际承运人实际提供，因货物运输产生的任何
纠纷及损失超出承运方上述最高赔偿限额的，托运人同意免除承运方责任并放弃追究承运方责任的权利，
并应由托运人直接向实际承运人求偿，承运方将尽力协助托运人向实际承运人求偿。</div>
          <div class="title">第七条 费用与结算</div>
          <div class="item">7.1 本协议项下，托运人应向承运方支付的运费双方通过平台确定，具体以平台中显示的数据为准
（包括但不限于出车记录、运单等），运费单价包含运输费、符合国家要求的增值税专用发票税费以及
道路通行维护费、过路过桥费、油费、场外道路整改费、护送车辆配置费等费用（如有）。</div>
          <div class="item">7.2 运输费用收取：托运人先行支付该笔运单的费用到承运方指定系统账号，再在系统中触发该运
单可结算的指令让承运方将司机应得运费支付到司机账号。承运方需向托运人透明每笔运单支付给实际
承运车辆的运费，针对该笔运单向托运人收取总运费的<el-input
              v-model="data.truck_plan"
              class="input"
              style="width: 100px;margin-left: 40px"
            ></el-input> %为承运方的毛利率。剩余部分线上结算
到实际承运的车辆，支付记录向托运人透明。承运方对实际承运人的运费不承担先行垫付的责任，托运方承担因其逾期支付运费给承运方及实际承运人造成的损失。</div>

          <div class="item">7.3 托运方付款账户信息:</div>
          <div class="item">单位名称： {{data1.name}}</div>
          <div class="item">纳税人识别号： {{data1.license_code}}</div>
          <div class="item">开户银行： {{data1.bank_name}}</div>
          <div class="item">银行账号： {{data1.bank_card}}</div>
          <div class="item">联行号：</div>
          <div class="item">地址：{{data1.addr}}</div>
          <div class="item" style="margin-left: 40px;">承运方收款账户信息:</div>
          <div class="item">单位名称： 安徽省骏功物联网科技有限公司</div>
          <div class="item">纳税人识别号： 91340802MA2WF25Y7R</div>
          <div class="item">开户银行： 光大银行南京分行营业部</div>
          <div class="item">银行账号： 76490180802760577 </div>
          <div class="item">行联号 ：303301000501</div>
          <div class="item">7.4承运方将货物按照约定运抵卸货地点后，托运人需将应支付的运费及时支付给承运方。</div>
          <div class="item">7.5托运人已经结清全款运费且符合承运方运单审验规则的运单，承运方在运单审验通过情况下，根
据托运人的申请就已实际收取的运费向托运人开具货物运输增值税专用发票（发票税率随国家相关法律
规则和政策调整），具体发票开具信息以平台信息为准。</div>
          <div class="item">7.6 根据税务机关以及平台规则等相关规定，承运方有权对运单真实有效性进行验证，托运人应积
极按照承运方的要求提供相关证明货物运输交易真实有效的证据和材料，承运方对托运人及实际承运人
提交的信息和资料进行审核，并结合运行轨迹等资料，对运单内容真实性及合理性进行验证和判定，若
运单审验未通过，承运方有权单方解除本协议，并由托运人承担承运方因此造成的全部损失，且相应运
单不予开具发票。若因前述情形致承运方错误开票的，承运方有权作废该发票或者红冲处理，且承运方
由此产生损失由托运人承担。</div>
          <div class="title">第八条 保密条款</div>
          <div class="item">任一方对因履行本协议从另一方获取或知悉的业务信息和文件（包括但不限于服务价格、运输线路、
银行账户等信息，以及客户和相关人员的个人信息等资料）负有保密义务，除政府及相关管理部门要求
披露或法律另有规定外，在本协议有效期内及协议终止后未经双方书面同意，任何一方均不得向任何第
三方透露保密信息亦不得为履行本协议以外的目的而使用保密信息。因一方泄密或不当使用而致使另一
方遭受损失的，泄密方或不当使用方应承担损害赔偿责任；情节严重的，追究其相关法律责任。</div>
          <div class="title">第九条 不可抗力</div>
          <div class="item">9.1 由于地震、台风、水灾、火灾、战争、国家政策、法律变化和社会疫情以及其他不能预见并对
其发生和后果不能预防或避免的不可抗力，直接影响本协议的履行或者不能按照协议的约定履行时，遇
有上述不可抗力的一方，应以最快方式通知对方，并在不可抗力消失后 10 个工作日之内，提供上述不可
抗力的详细情况及协议不能履行，或者部分不能履行，或者需要延期履行的理由和有效的证明文件。</div>
          <div class="item">9.2 因发生不可抗力，导致协议无法履行时，本协议期限经双方协商一致可顺延至不可抗力情况结
束之后。如果不可抗力事件持续存在超过 30 个工作日，双方应通过友好协商，商定继续履行本协议的方
法或者终止本协议。</div>
          <div class="item">9.3 一方因不可抗力不能履行本协议的，根据不可抗力的影响，该方部分或者全部免除责任，但适
用法律另有规定的除外。一方迟延履行后发生不可抗力的，该方不能免除责任。</div>
          <div class="title">第十条 责任条款</div>
          <div class="item">10.1 因托运人违反其应尽义务或其他托运人的原因，导致承运方遭受行政处罚、财产、采取防 护
措施产生的费用等损失，托运人应赔偿由此给承运方造成的损失。</div>
          <div class="item">10.2 因承运方原因造成货物损毁或丢失的，托运人有权要求赔偿。如任意一方就该批货物购买保险
的，发生保险事故后应当积极向保险公司进行索赔，另一方应当积极配合提供理赔材料。</div>
          <div class="item">10.3 若托运人未按单次道路货物运输服务协议约定时间支付给承运方相应的费用，托运人应以
未付费用为准，以每日万分之三的标准支付违约金给承运方；因托运人提出任何主张要求导致承运方及
关联方遭受任何损失或承担任何责任，则托运人应承担等于运费总额的违约金，并赔偿由此导致的承运
方全部直接与间接损失。</div>
          <div class="item">10.4 因托运人原因，或其他非承运方过错造成的货物毁损、灭失、遗失、延误、损耗等，承运方不
承担任何责任。</div>
          <div class="item">10.5 托运人承诺在与承运方进行道路货物运输合作期间，托运人不得从事网络货运相关经营活 动。
如合作期间托运人取得了网络货运资质的，即视为托运人从事网络货运相关经营活动，托运人构成根本
违约，承运方有权立即解除本协议，并有权要求托运人赔偿其直接或间接遭受的损失。</div>
          <div class="item">10.6 如果发生任何一方违反本协议约定的情形，守约方有权要求违约方在五日内纠正违约行为；如
果违约方未能在限定期限内补正的，则守约方有权书面通知对方解除本协议；如因一方违约给守约方造
成损失的，守约方有权要求违约方赔偿其所有直接损失、间接损失、索赔费用。</div>
          <div class="item">10.7 本协议中违约损失还包括但不限于因守约方为处理相关事项而支付的律师费用、诉讼费用、仲
裁费用、鉴定费用、差旅费等相关必要且合理的支出。</div>
          <div class="title">第十一条 通知与送达</div>
          <div class="item">本协议首页当事人联系信息适用于双方往来联系、文件书面或电子送达及争议解决时法律文书送达。
各方函件及法律文书，以到本协议首页联系方式时生效（快递发出之日起三日内视为到达），上述联系
方式如有变更必须提前5个工作日书面通知对方，否则视为未变更，自行承担由此造成无法送达或未及时
送达的法律后果。</div>
          <div class="title">第十二条 其他</div>
          <div class="item">12.1 本协议一式两份，双方各执一份，自双方签字盖章之日起生效，具有同等法律效力。</div>
          <div class="item">12.2 双方协商一致可提前解除本协议。</div>
          <div class="item">12.3 本协议自双方加盖有效协议章或公章之日起一年内有效。本协议到期前10个工作日，如双方未
对续签本协议提出异议的，则本协议期限自动顺延一年，延期次数不限。</div>
          <div class="item">12.4 鉴于无车承运业务模式的特殊性，协议双方确认同意，当相关法律法规、政策对无车承运业务
的相关规定发生变化并导致本协议的条款需要进行调整或变更时，承运方另行调整并通知托运人，经甲、
乙双方协商一致后另行签订协议或补充协议的，双方应按新的协议或补充协议执行，如协商不成，本协
议自协商不成之日起自动终止。</div>
          <div class="item">12.5 除本协议另有约定外，双方可在协商一致前提下终止本协议，但本协议的终止并不解除协议双
方对各自在协议终止前的行为所负的责任和义务。</div>
          <div class="item">12.6 双方在履行本协议过程中发生的任何争议应通过协商方式解决；协商不成的，双方均可向安庆
市迎江区的人民法院提起诉讼。</div>
          <div class="item">12.7 有关托运人与实际承运人之间的货物运输交易的一切安排及承运方所提供的具体服务，最终均
以承运方骏功物流网络货运平台所发布的规则、托运人与承运方签署的有关协议约定为准。</div>
          <div class="item">（以下无正文）</div>
          <!-- <div class="title">二、合作期限</div>
          <div class="item">
            本协议的合作期限：
            <el-input
              v-model="data.start_time_year"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 50px">年</span
            ><el-input
              v-model="data.start_time_monthly"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 50px">月</span
            ><el-input
              v-model="data.start_time_sun"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 50px">日</span> 至
            <el-input
              class="input"
              v-model="data.end_time_year"
              style="width: 80px"
            /><span style="margin-left: 50px">年</span
            ><el-input
              class="input"
              v-model="data.end_time_monthly"
              style="width: 80px"
            /><span style="margin-left: 50px">月</span
            ><el-input
              v-model="data.end_time_sun"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 50px">日</span> 。
          </div> -->
          <!-- <div class="title">三、运输方式</div>
          <div class="item">
            2.用车计划：<el-input
              v-model="data.truck_plan"
              class="input"
              style="width: 100px"
            ></el-input>
          </div>
          <div class="item" style="margin-left: 80px">
            <div>a.固定运力需求</div>
            <div>b.不固定运力需求</div>
          </div>
          <div class="item">3.用车计划变更提前告知义务</div>
          <div class="item">
            甲方因自身原因需要变更用车计划的（包括但不限于取消、推迟、提前、增减等），需至少提前24小时书面通知乙方，以便乙方有足够时间通知实际承运人，并妥善安排相关事宜。
          </div>
          <div class="item">
            4.车辆要求同时具备如下条件：（1）具备车辆行驶证、道路运输证等应具备的有效证件；（2）已缴纳当年度的机动车交通事故责任强制保险。甲方有权拒绝不符合以上要求的车辆进行承运，甲方有责任查验承运车辆是否符合上述要求，甲方同意车辆承运则视为甲方认可该车辆的承运资质。
          </div>
          <div class="title">四、乙方提供的其他服务</div>
          <div class="item">
            1.乙方通过骏功物流网络货运平台的流程设计尽可能地保障甲方发布货运信息及享受运输服务的过程安全、合规、标准、可控。
          </div>
          <div class="item">
            2.甲方应确保运费和服务费都通过骏功物流网络货运平台指定的线上支付方式支付给乙方和实际承运人。
          </div>
          <div class="item">
            为避免歧义，实际操作中，甲方可能在确认到货前向实际承运人垫付其他款项，已垫付款项应从线上运单运费中扣除。
          </div>
          <div class="item">
            3.乙方协同甲方对实际承运人在运输过程中车辆的行驶轨迹进行监控，实时了解车辆的定位信息，以便合理控制运输的风险。<span
              >如因不可抗力（自然灾害、政府行为、社会异常事件等）导致不能完成货物运输任务，乙方需提前告知甲方，并协助甲方将损失降低最小。</span
            >
          </div>
          <div class="item">
            4.运输交易完成后，乙方提供甲方与实际承运人之间的互相评价系统，建立评价信用体系，使得甲方与实际承运人可以互相评价，并将评价结果在骏功物流网络货运平台公布，以更好地监督实际承运人的服务质量。
          </div>
          <div class="title">五、发票申请要求</div>
          <div class="item">1.开票过程</div>
          <div class="item">
            申请开具发票以双方已签订运输协议并成功生成系统线上合规运单、甲方已依约履行完毕该运输协议且甲方已支付完毕该运输协议项下的全部运单运费为前提和标准，就发生符合上述开票标准的双方之间运输交易对应的运单运费，甲方应在乙方规定期间通过骏功物流网络货运平台向乙方提交开票申请，经乙方对甲方已结清全款的运单金额审核无误后，乙方就其已从甲方实际收取的承运费向甲方开具税率9%的货物运输增值税专用发票（税率随国家相关法律法规和政策调整）。
          </div>
          <div class="item">
            根据甲方相关真实运输交易具体情况和税务机关的要求等，乙方有权要求甲方提供相应的信息与资料用于证明其业务真实性，甲方应积极配合并及时提供。根据税务机关的具体要求以及骏功物流网络货运平台规则，为维护骏功物流网络货运平台秩序，乙方有权对本协议项下的运单真实有效合规性进行验证，甲方应积极配合，按照乙方要求提供证明货物运输交易真实有效合规的证据和资料。若甲方在本协议项下的运单真实有效合规性验证未获乙方审核通过的，乙方有权选择解除具体的货物运输交易协议、拒绝向甲方开具发票，因此产生的损失由甲方自行承担。
          </div>
          <div class="item">2.开票金额</div>
          <div class="item">乙方就实际收取的甲方承运费开具增值税专用发票。</div>
          <div class="item">
            若甲方另行向实际承运人支付任何其他款项或实物（统称“其他款项或实物”，包括但不限于现金、ETC卡、油卡等，无论甲方与实际承运人是否以运费的名义支付与收取该等其他款项或实物），则该等其他款项或实物为实际承运人从甲方取得的收入，与乙方作为无车承运人的业务无关，该等款项或实物对应的金额、票据取得应由甲方和实际承运人自行进行约定和结算。
          </div>
          <div class="item">3.甲方确认其开票信息如下</div>
          <div class="item">企业名称:{{ data1.name }}</div>
          <div class="item">法定代表人:{{ data1.legal_person }}</div>
          <div class="item">住所地:{{ data1.addr }}</div>
          <div class="item">纳税识别号:{{ data1.license_code }}</div>
          <div class="item">电话:{{ data1.app_user_info.tel }}</div>
          <div class="item">开户行:{{ data1.bank_name }}</div>
          <div class="item">银行账号:{{ data1.bank_card }}</div>
          <div class="item" style="margin-left: 80px">
            乙方指定运费结算账户如下：
          </div>
          <div class="item">单位名称: {{ unit_name }}</div>
          <div class="item">纳税人识别号:{{ taxpayer_code }}</div>
          <div class="item">开户银行:{{ bank_account }}</div>
          <div class="item">银行账号:{{ bank_num }}</div>
          <div class="item">单位地址:{{ unit_addr }}</div>
          <div class="item">联系电话:{{ unit_tel }}</div>
          <div class="item">
            4.乙方承诺其向甲方出具的发票系税务部门监制的、真实合法有效的发票。如乙方出具给甲方的是虚假发票，所产生所有责任由乙方承担，与甲方无关。
          </div>
          <div class="title">六、风险转移及赔偿限额</div>
          <div class="item">
            1.货交承运人后风险转移，即甲方将货物交付给实际承运人后货物毁损或灭失的风险转移。由于网络货运平台经营模式的特殊性，货物并不经手乙方，而是直接交到实际承运人手上，因此货物发生损坏或灭失后，先由乙方要求实际承运人承担赔偿责任，再由乙方向甲方承担赔偿责任。但货物的毁损、灭失是因不可抗力、货物本身的自然属性或者合理损耗以及托运人、收货人的过错造成的（包括但不限于易碎品、贵重物品、高附加值品等未提前书面告知且未选择投保），乙方不承担赔偿责任。
          </div>
          <div class="item">
            2.甲方应在将货物交付实际承运人运输之前自行购买货运综合险、货物责任险及其他相关保险，确保在发生货损时可获得保险公司的赔付，货物运输保险受益人为甲方，保险费用由甲方承担。若甲方未及时购买或购买的货运险保险金额不足以覆盖货损及其他损失和责任的，甲方应提前书面告知乙方，由乙方提供追加投保的服务，追加保险的金额由甲方承担。双方确认，在本协议合作范围内乙方对双方签订的每笔运单项下货物的毁损、灭失、逾期等的最高赔偿的限额为服务费的两倍，乙方对超出该限额的损失不承担任何赔偿责任。
          </div>
          <div class="item">
            3.甲方确认并知晓，本协议下的货物运输服务由实际承运人实际提供，因货物运输产生的任何纠纷及损失超出乙方上述最高赔偿限额的，甲方同意免除乙方责任并放弃追究乙方责任的权利，并应由甲方直接向实际承运人求偿，乙方将尽力协助甲方向实际承运人求偿。
          </div>
          <div class="title">七、甲方的其他权利义务</div>
          <div class="item">
            1.甲方保证其在乙方骏功物流网络货运平台所发布的货运信息真实、准确、完整，严格遵守相关法律法规合法经营，不存在虚假、遗漏或误导的情况。
          </div>
          <div class="item">
            2.甲方有责任有义务保密本协议内容，若甲方违反，乙方有权追究法律责任。
          </div>
          <div class="item">
            3.为增加甲方所发布货运信息的可信度，甲方同意乙方可将甲方的名称、商标、标识等资料展示于骏功物流平台内。<span>
              严禁甲方要求乙方业务人员为其代操作系统。</span
            >
          </div>
          <div class="item">
            4.甲方的货物运输需要办理审批、检验等手续的，甲方应当将办理完有关手续的文件提交实际承运人并及时通知乙方。甲方应当按照约定的方式或适合安全运输的方式包装货物。因货物包装问题导致的货物损失，由甲方自行承担。
          </div>
          <div class="item">
            5.甲方应确保托运货物不属于法律规定的违禁品及危险品。
          </div>
          <div class="item">6.甲方应保证发票所涉交易的真实性。</div>
          <div class="item">
            7.甲方应当自觉遵守乙方骏功物流网络货运平台发布的各类规则，包括《个人信息管理规则》《交易规则和保障条款》《用户授权协议》，维护骏功物流网络货运平台的交易秩序。
          </div>
          <div class="title">八、乙方的其他权利义务</div>
          <div class="item">
            1.乙方将协助和指导甲方在骏功物流网络货运平台开通专属于甲方的账户，甲方应出具其法定代表人或其他代理人（非乙方工作人员）操作甲方账户的授权委托书。在双方合作期间，甲方应保管好账户名及密码，如甲方的账户名或密码遗失或泄露的，应第一时间书面通知乙方，乙方可根据甲方书面要求及时免费为甲方进行账户重置或找回密码。
          </div>
          <div class="item">
            2.乙方将持续对骏功物流网络货运平台进行技术优化及改进，维护平台的稳定运行，建立完善的客户响应机制，以确保甲方获得优质、高效的服务。在骏功物流网络货运平台出现故障时，乙方应尽快解决问题、排除相关故障。
          </div>
          <div class="item">
            3.乙方有权基于自身业务发展需要、主管机关要求或政策变化的要求，变更、调整或取消本协议项下的相关服务，届时以乙方实际提供的服务为准。
          </div>
          <div class="item">
            4.乙方将设立客户服务热线，号码为0556-5888588，为甲方解答、处理在系统使用过程中遇到的问题。
          </div>
          <div class="item">
            5.甲方与实际承运人之间产生本协议约定的特定争议，乙方协调双方解决。
          </div>
          <div class="title">九、协议的解除及违约责任</div>
          <div class="item">1.双方协商一致可提前解除本协议。</div>
          <div class="item">
            2.甲方违反本协议、运单或在线交易协议、平台相关规则等约定的，乙方有权解除本协议。如因此给乙方造成损失的，甲方须赔偿乙方的全部损失。
          </div>
          <div class="item">
            3.甲方逾期支付运费的，每逾期一日，应向乙方支付应付而未付款项的利息，日利率0.05%；甲方逾期支付超过五日的，乙方有权直接解除本协议，并有权要求甲方赔偿其所有直接损失、间接损失、索赔费用。
          </div>
          <div class="item">
            4.甲方未履行变更用车计划提前告知义务的，乙方由此造成的所有直接损失、间接损失、索赔费用皆由甲方承担。
          </div>
          <div class="item">
            5.因甲方自己原因导致发票需要红冲的，应提前与乙方协商，并且乙方有权扣除服务费的40%作为误工费。
          </div>
          <div class="item">
            6.如果发生任何一方违反本协议约定的情形，守约方有权要求违约方在五日内纠正违约行为；如果违约方未能在限定期限内补正的，则守约方有权书面通知对方解除本协议；如因一方违约给守约方造成损失的，守约方有权要求违约方赔偿其所有直接损失、间接损失、索赔费用。
          </div>
          <div class="title">十、其他</div>
          <div class="item">
            1.本协议一式两份，双方各执一份，自双方签字或盖章之日起生效（需加盖骑缝章）。
          </div>
          <div class="item">
            2.鉴于无车承运业务模式的特殊性，甲、乙双方确认同意，当相关法律法规、政策对无车承运业务的相关规定发生变化并导致本协议的条款需要进行调整或变更时，乙方另行调整并通知甲方，经甲、乙双方协商一致后另行签订合同或补充协议的，双方应按新的合同或补充协议执行，如协商不成，本协议自协商不成之日起自动终止。
          </div>
          <div class="item">
            3.双方在履行本协议过程中发生的任何争议应通过协商方式解决；协商不成的，向<span
              >乙方所在地的</span
            >
            人民法院提起诉讼。
          </div>
          <div class="item">
            4.双方因履行本协议或任何与本协议相关的函件、通知或往来材料都应当是书面形式，并以中国邮政特快专递、中国邮政快递或电子邮件的方式发送。<span
              >本协议页首当事人联系方式和联系信息适用于双方往来联系、书面文件送达及争议解决时法律文书送达。</span
            >
          </div>
          <div class="item">
            5.有关甲方与实际承运人之间的货物运输交易的一切安排及乙方所提供的具体服务，最终均以乙方骏功物流网络货运平台所发布的规则、甲方与乙方签署的有关协议约定为准。
          </div>
          <div class="item">（以下无正文，为本协议之签署栏）</div> -->
          <div class="contract_sign" style="margin-bottom: 100px">
            <div class="left">
              <div class="item">甲方：{{ data.name }}</div>
              <div class="item">(公司公章)</div>
              <div class="item">法定代表人：{{ data.legal_person }}</div>
              <div class="item">授权代表人：</div>
              <div class="item">
                日期：
                <input
                  type="text"
                  v-model="time4"
                  class="input"
                  style="width: 300px"
                />
              </div>
            </div>
            <div class="right">
              <div class="item">乙方：安徽省骏功物联网科技有限公司</div>
              <div class="item">(公司公章)</div>
              <div class="item">法定代表人：黄宇翔</div>
              <div class="item">授权代表人：</div>
              <div class="item">
                日期：
                <input
                  type="text"
                  v-model="time3"
                  class="input"
                  style="width: 300px"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="head" style="margin-top: 300px">
          <div
            class="top"
            style="font-size: 35px; font-weight: bold; color: black"
          >
            骏功物联补充协议书
          </div>
        </div>
        <div class="head-right">
          <div class="contract_number">
            合同编号：
            <input
              type="text"
              v-model="contract_num1"
              class="input"
              style="width: 300px"
            />
          </div>
          <div class="contract_addr">合同签订地：安庆市迎江区</div>
        </div>
        <div class="form_info" style="margin-top: 30px">
          <div class="item">乙方（承运方）：安徽省骏功物联网科技有限公司</div>
          <div class="item">法定代表人：黄宇翔</div>
        </div>
        <div class="content_tips">
          <div class="item">
            甲、乙双方通过友好协商，以双赢的目的，建立长期合作关系；通过骏功物流平台（网络货运平台）进行货物运输业务，服务费事宜协议如下：
          </div>
          <div class="title">一、服务费概况：</div>
          <div class="item">
            1.运单运费：甲方就其在乙方平台实名注册发布并生成的每个有效的无车承运运单对应的运费。运单运费由甲方根据真实业务需求，在乙方平台设立的系统余额账户中充值，当订单生效后，甲方可自行通过系统操作发出指令，指示乙方以扣除余额额度方式向正在运输或已完成运输运单的实际承运人支付部分或全部运费。运单运费具体金额以骏功物流网络货运平台显示的金额为准。
          </div>
          <div class="item">
            2.服务费：就乙方向甲方提供的无车承运业务相关服务（包括但不限于对订单进行监控和跟踪、信息处理、客户服务等），甲方应当按照合同约定的服务费金额向乙方支付服务费。
          </div>
          <div class="item">
            3.为避免歧义，本补充协议中“服务费”名称仅为理解与计算方便，与“运单运费”名称做显著区分，服务费性质仍属于乙方作为网络货运经营者实际收取的承运费的一部分。
          </div>
          <div class="item">
            合作期限：
            <el-input
              v-model="data.start_time_year1"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 30px">年</span
            ><el-input
              v-model="data.start_time_monthly1"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 30px">月</span
            ><el-input
              v-model="data.start_time_sun1"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 30px">日</span> 至
            <el-input
              class="input"
              v-model="data.end_time_year1"
              style="width: 80px"
            /><span style="margin-left: 30px">年</span
            ><el-input
              class="input"
              v-model="data.end_time_monthly1"
              style="width: 80px"
            /><span style="margin-left: 30px">月</span
            ><el-input
              v-model="data.end_time_sun1"
              class="input"
              style="width: 80px"
            /><span style="margin-left: 30px">日</span> 。
          </div>
          <div class="title">三、运作方法：</div>
          <div class="item">
            1.甲乙双方商定服务费，甲方服务费率
            <el-input v-model="data.fl" class="input" style="width: 80px" />
            <span style="margin-left: 30px">
              %；国家对运输增值税比率不变动的情况下，服务费率不予变动。计算公式如下：</span
            >
          </div>
          <div class="item">服务费=运费/（1－服务费率）－运费</div>
          <div class="item">
            2.承运费的支付：甲乙双方约定结算周期选用 方式：<el-input
              v-model="data.settlement_way"
              class="input"
              style="width: 100px"
            ></el-input>
          </div>
          <div class="item" style="margin-left: 80px">
            <div>A.实时结算</div>
            <div>
              B.<el-input
                v-model="data.days"
                class="input"
                style="width: 100px"
              ></el-input>
              <span style="margin-left: 30px">天一结</span>
            </div>
          </div>
          <div class="item">
            甲方逾期支付的，每逾期一日，应向乙方支付应付而未付款项的利息，日利率0.05%；逾期支付超过五日的，乙方有权直接解除本协议，并有权要求甲方赔偿乙方遭受的一切损失（包括但不限于诉讼费、保全费、公证费、律师费、鉴定费等）及开支。
          </div>
          <div class="item">
            3.甲方运输业务完成后，甲方向乙方支付服务费，乙方收到服务费后给甲方开具运输增值税专用发票。
          </div>
          <div class="title">四、争议解决：</div>
          <div class="item">
            1.甲方保证其在乙方骏功物流网络货运平台所发布的货运信息真实、准确、完整，严格遵守相关法律法规合法经营，不存在虚假、遗漏或误导的情况。
          </div>
          <div class="item">2.若本协议与主合同有冲突，以主合同为准。</div>
          <div class="item">
            3.双方应对合同相关信息进行保密管理，未经同意不得泄露、出售或者非法向他人提供，不得使用相关信息开展其他业务。双方对本合同的所有细节进行保密，未经一方同意，另一方不得以任何方式转让或披露给第三方（但法律强制规定或有关政府机关、监管机构强制要求提供的除外）。
          </div>
          <div class="item">
            4.协议履行过程中发生争议，双方协商解决。协商不成的，应提交至乙方所在地人民法院诉讼解决。
          </div>
          <div class="item">
            5.不可抗力因素造成收税成本上涨（政府性质），根据实际情况再商定服务费。
          </div>
          <div class="item">
            6.因甲方自己原因（包括但不限于乙方自查发现问题、监管部门监察发现问题）导致发票红冲作废的，乙方有权扣除服务费的40%作为误工费。
          </div>
          <div class="item">
            7.本协议一式两份，甲乙双方各执一份。双方签字、盖章后立即生效（需加盖骑缝章）。
          </div>
          <div class="item">（以下无正文，为本协议之签署栏）</div>
          <div class="contract_sign">
            <div class="left">
              <div class="item">甲方：{{ data.name }}</div>
              <div class="item">(公司公章)</div>
              <div class="item">法定代表人：{{ data.legal_person }}</div>
              <div class="item">授权代表人：</div>
              <div class="item">
                日期：
                <input
                  type="text"
                  class="input"
                  style="width: 300px"
                  v-model="time2"
                />
              </div>
            </div>
            <div class="right">
              <div class="item">乙方：安徽省骏功物联网科技有限公司</div>
              <div class="item">(公司公章)</div>
              <div class="item">法定代表人：黄宇翔</div>
              <div class="item">授权代表人：</div>
              <div class="item">
                日期：
                <input
                  type="text"
                  v-model="time1"
                  class="input"
                  style="width: 300px"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <el-button type="primary" @click="getPdfFromHtml()">合同下载</el-button>
  </el-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { Loading } from "element-ui";
export default {
  props: {
    is_show: Number,
    data: Object,
    type: Number,
  },
  data() {
    return {
      //是否显示
      is_show_in_page: false,

      unit_name: "安徽省骏功物联网科技有限公司",
      taxpayer_code: "91340802MA2WF25Y7R",
      bank_account: "徽商银行股份有限公司安庆中兴路支行",
      bank_num: "225004083101000006",
      unit_addr:
        "安徽省安庆市迎江区绿地迎江世纪城紫峰大厦B座2401、2420、2421室",
      unit_tel: "0556-5888588",
      contract_num1: "",
      contract_num: "",
      pdf_base64: "",
      time1: "",
      time2: "",
      time3: "",
      time4: "",
      data1: {
        name: "",
        legal_person: "",
        addr: "",
        license_code: "",
        bank_name: "",
        app_user_info: {
          tel: "",
        },
        days: "",
        bank_card: "",
        start_time_year: "",
        start_time_monthly: "",
        start_time_sun: "",
        end_time_year: "",
        end_time_monthly: "",
        end_time_sun: "",

        start_time_year1: "",
        start_time_monthly1: "",
        start_time_sun1: "",
        end_time_year1: "",
        end_time_monthly1: "",
        end_time_sun1: "",

        truck_plan: "",

        settlement_way: "",

        fl: "", //费率
      },
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.init(this.data);
        //打开弹出层
        this.is_show_in_page = true;
      } else {
        //关闭弹出层
        this.is_show_in_page = false;
      }
    },
  },
  methods: {
    //初始化
    init(new_data) {
      this.data1 = new_data;
    },
    //弹窗关闭清空数据
    close() {
      (this.contract_num1 = ""),
        (this.contract_num = ""),
        (this.time1 = ""),
        (this.time2 = ""),
        (this.time3 = ""),
        (this.time4 = ""),
        (this.pdf_base64 = ""),
        (this.data1 = {
          name: "",
          legal_person: "",
          addr: "",
          license_code: "",
          bank_name: "",
          app_user_info: {
            tel: "",
          },
          days: "",
          bank_card: "",
          start_time_year: "",
          start_time_monthly: "",
          start_time_sun: "",
          end_time_year: "",
          end_time_monthly: "",
          end_time_sun: "",

          start_time_year1: "",
          start_time_monthly1: "",
          start_time_sun1: "",
          end_time_year1: "",
          end_time_monthly1: "",
          end_time_sun1: "",

          truck_plan: "",

          settlement_way: "",
        });
    },
    contract_up() {
      var file = this.$my.base64.dataURLtoFile(this.pdf_base64, "pdf文件");
      let res = {
        name: file.name,
        size: file.size,
        type: file.type,
      };
      if (window.createObjectURL != undefined) {
        // basic
        res.src = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        res.src = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        res.src = window.webkitURL.createObjectURL(file);
      }
      res.src = this.$my.qiniu.files_cache[res.src] = file;
      this.$my.qiniu.upl({
        bucket: "pdf",
        key: "",
        file_path: res.src,
        callback: (data) => {
          var src = this.$my.qiniu.make_src("pdf", data.key);
          console.log(src);
          this.$emit("contract_up", this.type, data.key);
          this.is_show_in_page = false;

          // this.company_pdf.src = pdf.createLoadingTask(src)
          // this.company_pdf.src.promise.then(pdf => this.pageTotal = pdf.numPages).catch(error => {})
          // var src = this.$my.qiniu.make_src('pdf',data.key)
          // this.company_pdf.src = src
          // this.company_pdf.up_show = false
        },
      });
    },
    toGetPdf() {
      this.getPdf("测试导出PDF");
    },
    getPdfFromHtml(ele, pdfFileName) {
      Loading.service(this.options);

      var that = this;
      ele = this.$refs["content"];
      pdfFileName = pdfFileName || "pdf";
      window.pageYoffset = 0; // 滚动置顶
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      // ele.style.fontFamily='宋体';
      // ele.style.padding='30px';
      let scale = window.devicePixelRatio * 2;
      html2canvas(ele, {
        // dpi: 300,
        dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
        scale: 0.8, //按比例增加分辨率
        logging: false,
        // scale:scale,
        useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
        allowTaint: false,
        height: ele.offsetHeight,
        width: ele.offsetWidth,
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        backgroundColor: "#fff",
      }).then((canvas) => {
        const _this = this;
        //未生成pdf的html页面高度
        var leftHeight = canvas.height;
        var a4Width = 555.28; // 原A4宽 592 因为要设置边距 20 ,这里要减掉 40
        var a4Height = 801.89; // 原A4高   841 因为要设置边距 20 ,这里要减掉 40
        //一页pdf显示html页面生成的canvas高度;
        var a4HeightRef = Math.floor((canvas.width / a4Width) * a4Height);

        //pdf页面偏移
        var position = 0;

        var pageData = canvas.toDataURL("image/jpeg", 1.0);

        var pdf = new JsPDF("x", "pt", "a4");
        var index = 1,
          canvas1 = document.createElement("canvas"),
          height;
        pdf.setDisplayMode("fullwidth", "continuous", "FullScreen");

        function createImpl(canvas) {
          if (leftHeight > 0) {
            index++;
            var checkCount = 0;
            if (leftHeight > a4HeightRef) {
              var i = position + a4HeightRef;
              for (i = position + a4HeightRef; i >= position; i--) {
                var isWrite = true;
                for (var j = 0; j < canvas.width; j++) {
                  var c = canvas.getContext("2d").getImageData(j, i, 1, 1).data;

                  if (c[0] != 0xff || c[1] != 0xff || c[2] != 0xff) {
                    isWrite = false;
                    break;
                  }
                }
                if (isWrite) {
                  checkCount++;
                  if (checkCount >= 10) {
                    break;
                  }
                } else {
                  checkCount = 0;
                }
              }
              height =
                Math.round(i - position) || Math.min(leftHeight, a4HeightRef);
              if (height <= 0) {
                height = a4HeightRef;
              }
            } else {
              height = leftHeight;
            }

            canvas1.width = canvas.width;
            canvas1.height = height;

            // console.log(index, 'height:', height, 'pos', position);

            var ctx = canvas1.getContext("2d");
            ctx.drawImage(
              canvas,
              0,
              position,
              canvas.width,
              height,
              0,
              0,
              canvas.width,
              height
            ); // 边距这里设置0，不然又黑边

            var pageHeight = Math.round((a4Width / canvas.width) * height);
            // pdf.setPageSize(null, pageHeight)
            if (position != 0) {
              pdf.addPage();
            }
            // 设置 20px 边距
            pdf.addImage(
              canvas1.toDataURL("image/jpeg", 1.0),
              "JPEG",
              20,
              20,
              a4Width,
              (a4Width / canvas1.width) * height
            );
            leftHeight -= height;
            position += height;
            // $('.pdfProgress').text(index + 1);
            // $('.pdfTotal').text(index + Math.ceil(leftHeight / a4HeightRef));
            if (leftHeight > 0) {
              //添加全屏水印
              const base64 =
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAArCAYAAADIWo5HAAACLUlEQVR42u2Zy2sCMRDGV9tbtVJKrQ/0JBWLvbgsaOttQQqreO5R6GHP/v+HZiEDQ9i8k33YDHyXbcxufpN8maRRFCJEiBAhQoTgRIfoh+hKNBe0G9I2GVG3om9bEl2I9rcIoE/77Cq8Myd6sn2ZSBgAr80YARhI+gOwCdGW6EB/d6R9XDUGFrsAf2Fe7Fswk1JBmzMd1DNKgI2EywQyUKYjkxFeOwwxkwgAvBJNqEa0DzaTHcn3qWrXdA/ocQD8m11ABUDfQF0fpujjI2QAeoZetdYdfOzB9HgGNEF6owM8Ec3Q8wEDgP07T6ktgNyB8YgAqGZ0b+gRsS2AtQP/iAUAHlAdsEW7TY6eFVrVNQN8A2AjlZho5R5wYjJhoqMiADy4DFWLPJMcKBhv3AYThFi4yJyrAAAfDrY/1SVQVhLDOWDowIwzSQ1TqwfA1P5CU3yJlsPY8fmjcTMgptmeIwB3aFbsLCvM2BRAFR4AZXYxuEdmn38hmjoosY0B5IonxLNhIYTNby0odMoAJJL1/k10bwpgQztZKaxb2YXFRnAUhexDHzoAUsGxu3j+awNA5+RmcxW1UCx1eQB4SyI1AaBrbiMEYGrwexgYBlgrgGtU/ZUY3AlGhgByTvV5MQGgW1SomqBOUWLiATy1wgNsABQz51PzWHxTALzWAW0F0EM+MKPmnLj6x0lbAPDuC5zfNPsCcChZ25DZd4VBJCW7wrRp1+whQoQIESJEg+IPRa38G55TPkQAAAAASUVORK5CYII=";
              for (let i = 0; i < 6; i++) {
                for (let j = 0; j < 5; j++) {
                  const left = j * 120 + 20;
                  // pdf.addImage(base64,'JPEG', left, i*150, 20, 30); // 关掉水印
                }
              }
              setTimeout(createImpl, 500, canvas);
            } else {
              //  that.pdf_base64 = pageData;
              //  that.contract_up()
              let loadingInstance = Loading.service(this.options);
              that.$nextTick(() => {
                loadingInstance.close();
              });
              pdf.save(pdfFileName + ".pdf");
            }
          }
        }
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < a4HeightRef) {
          pdf.addImage(
            pageData,
            "JPEG",
            20,
            20,
            a4Width,
            (a4Width / canvas.width) * leftHeight
          );
          // that.pdf_base64 = pageData;
          // that.contract_up()
          let loadingInstance = Loading.service(this.options);
          that.$nextTick(() => {
            loadingInstance.close();
          });
          pdf.save(pdfFileName + ".pdf");
        } else {
          try {
            pdf.deletePage(0);
            setTimeout(createImpl, 500, canvas);
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  font-size: 25px;
}
.contract-container {
  margin-right: 5%;
  width: 95%;
  .head {
    padding-left: 30%;
    font-size: 25px;
    .top1 {
      margin-left: 80px;
      margin-top: 20px;
    }
  }
  .head-right {
    margin-left: 65%;
    .contract_addr {
      margin-top: 10px;
    }
  }
  .input {
    border: none;
    border-bottom: 1px solid #cccccc;
    font-size: 24px;
    width: 80%;
    text-indent: 1em;
  }
  .content {
    margin-left: 10%;
    .form_info {
      .item {
        margin-top: 10px;
      }
    }
    .content_tips {
      margin-top: 10px;
      .item {
        margin-top: 10px;
        text-indent: 2em;
      }
      .title {
        margin-top: 10px;
        font-weight: bold;
        color: black;
      }
    }

    /deep/.el-input__inner {
      border: none !important;

      box-shadow: none !important;

      padding: 0px; //前边边距去掉
    }
    .contract_sign {
      margin-top: 20px;
      display: flex;
      .right {
        margin-left: 400px;
      }
    }
  }
}
</style>